import React from "react"

import HeaderClients from "../../components/header_clients"
import BusinessOwnerPic from "../../static/images/BusinessOwner.jpg"

export default function BusinessOwner() {
  const heroTitle = "Business Owner"

  return (
    <HeaderClients heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <figure class="image-block-wrapper mb-4">
        <img src={BusinessOwnerPic} alt={heroTitle} class="img-fluid img-thumbnail" />
      </figure>

      <p>
        Individuals running their own business typically have paucity of time to focus on personal
        finance.
      </p>

      <p>
        At Apricus, we are conscious that you are focussed on building your business. Thinking about
        your work, employees, competitors, industry dynamics, cash flows and juggling family life
        would be your major concerns and not how and when you will retire. Your time is precious and
        we understand that when you are so busy, you simply may not have the energy to deal with
        your finances.
      </p>

      <p>
        We are here to make sure that you make the right financial decisions. We will act as your
        personal financial officer and use cash-flow and lifestyle planning to identify where you
        are now financially, and where you want to be.
      </p>

      <p>
        We aim to develop a comprehensive understanding of your financial needs and financial goals.
        Based on the objectives, we shall assist in designing a financial plan that helps you meet
        your long term goals. Ideally, the financial plan will provide clarity, focus and confidence
        in your financial well being rather than unnecessarily worrying about do’s and don’ts for
        your assets.
      </p>
    </HeaderClients>
  )
}
